  const redirectBar = document.getElementById('redirect-bar');
  const closeRedirectButton = document.getElementById('redirect-bar-close');

  if (!Cookies.get('redirectClosed')) {
    redirectBar.style.display = 'block';
  }

  closeRedirectButton.addEventListener('click', function () {
    redirectBar.style.display = 'none';
    Cookies.set('redirectClosed', true, { expires: 365 });
  });
